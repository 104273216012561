<template>
  <div>
    <!-- picA3B0C9AD84A79A4DF4AEBB152926F729.jpg -->
    <img style="width: 200px; height: 235px; float: left; margin-right: 10px;" class="rounded" src="@/assets/news-network/create-digital-designs-the-lu-way-3.jpg">
    <p>
      Welcome to part three of this special series on building your best creations in LEGO Universe! In this edition, the lead LEGO Universe world artist, Nathan Storm, pilots us into a few of the biggest challenges his team faces as they help design LEGO models for the game world!
    </p>
    <p>
      LEGO Universe art director, Phillip Atencio, says world artists build the bulk of the 3D game world—including its terrain, clouds, trees and, of course, LEGO models. When the world art team is called upon to build a brick model, Nathan gets concept art of the creation and his team springs into action.
    </p>
    <p>
      Nathan usually passes the concept art to either a LEGO Universe partner (LUP) or to one of the other expert LEGO modelers on his team. Those folks then create and build a physical model that they’ll copy in digital bricks.
    </p>

    <!-- picEF34E509C2786FC2302E368858CCCE2F.jpg -->
    <img style="height: 330px;" class="rounded" src="@/assets/news-network/create-digital-designs-the-lu-way-3-1.jpg">

    <p><font size="1"><i>Example of a model inspired by concept art</i></font>
    </p>
    <p>
      Nathan says one reason his team normally builds physical LEGO models before going digital is that, “Digital creations can be much more flexible than physical ones. For instance, we could design digital models that wouldn’t physically hold together. Building with physical LEGO bricks helps us keep that connectivity in LEGO Universe!”
    </p>
    <p>
      Physical building can also benefit world artists when they need to work out “SNOT” strategies. No, this isn’t the stuff that sometimes drips from noses! It stands for <b>S</b>tuds <b>N</b>ot only <b>O</b>n <b>T</b>op! “Studs” are the round pokey parts on the tops of basic LEGO bricks, and “snotting” gets studs on more sides than just the one. The methods can be tricky, but snotting inside a model might help a world artist make their creation super cool using fairly few bricks. <br></p>

    <!-- pic15D8A50EB3BCDF50AB36BCCDB327BCFD.jpg -->
    <img style="height: 330px;" class="rounded" src="@/assets/news-network/create-digital-designs-the-lu-way-3-2.jpg">

    <p><font size="1"><i>Example of SNOT techniques</i></font>
    </p>
    <p>
      Nathan says that managing brick counts can help keep LEGO Universe running smoothly on computers. He explains that the game’s bricks are made from digital “polygons.” Polygons are like the building blocks of 3D virtual space; they interconnect to form the visible game world. Making part of that world from a massive polygon pile could lay a heavy load on computer memories.
    </p>
    <p>
      Another polygon-preserving trick Nathan’s team has learned is to make mostly smooth models. He says that for a two by four LEGO Universe brick, the eight studs alone can account for more than half of its polygons! That’s one of the reasons why most of the bumpy studs are covered on the battle training mechs pictured in this series!
    </p>

    <!-- picA2EFB404815FB4C42626FFB9CCEE8D25.jpg -->
    <img style="height: 264px;" class="rounded" src="@/assets/news-network/create-digital-designs-the-lu-way-3-3.jpg">

    <p>
      <font size="1"><i>Close-up of the smooth surface of a mech</i></font>
    </p>
    <p>
      Phillip Atencio, confirms that presiding over polygon counts is a critical skill for the game’s world artists; they need to have strong visual <i>and</i> technical skills. Like kids with their allowance, LEGO Universe world artists are always hunting for ways to stretch their polygon budgets!
    </p>
    <p>
      Stay tuned to the LEGO Universe News Network to learn a few more of Nathan’s tricks in part four of this special series!
    </p>
    <p>
      <br><b><i>This edition’s LEGO Universe building tips:</i></b>
    </p>
    <p>
      • Try out physical models before launching your creation in virtual space!
      <br>
      • Have a go at SNOT building!
    </p>
    <p>
      <br><i>Follow these links if you missed earlier editions of this special series!</i>
    </p>
    <p>
      <router-link :to="{ name: 'story', params: {id: 82873} }">
        Creating digital designs the LEGO Universe way: Part 1
      </router-link>
      <br>
      <router-link :to="{ name: 'story', params: {id: 88803} }">
        Creating digital designs the LEGO Universe way: Part 2
      </router-link>
    </p>
  </div>
</template>
